import React from 'react';
import { styled } from '@mui/material/styles';
import { Button, Typography, Box } from '@mui/material';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import ArrowForwardIosRoundedIcon from '@mui/icons-material/ArrowForwardIosRounded';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import moment from 'moment';
import { sortBy } from 'lodash';
import { useTranslation } from 'react-i18next';
import 'moment/locale/fr' 
import { eventTypeEnum } from '../types';

const DateButton = styled(Button)({
	padding: 0,
	width: 50,
});

const DateContainer = styled('div')({
	margin: '0 auto',
	marginBottom: 50,
	'& .slick-track': {
		borderWidth: 1,
		borderColor: 'lightgrey',
		borderStyle: 'solid',
		borderRadius: 4,
		width: 'fit-content'
	}
});

const ButtonHeader = styled('div')({
	backgroundColor: 'darkgrey',
	width: '100%',
	padding: '5px 0',
	color: '#FFFFFF',
	fontWeight: 'bold',
	fontSize: 13
});

const ButtonContent = styled('div')({
	width: '100%',
	height: '100%',
	padding: '5px 0',
	display: 'flex',
	flexDirection: 'column',
	justifyContent: 'center',
	alignItems: 'center',
	color: '#000000',
});

const NavNextButton = styled('button')({
	justifyContent: 'center',
	alignItems: 'center',
	height: '40px',
	width: '40px',
	borderStyle: 'solid',
	borderWidth: '1px',
	borderRadius: '4px',
	marginLeft: 30,
	marginRight: 30,
	right: -100,
	color: 'lightgrey',
	'&:hover': {
		display: 'block',
		color: 'lightgrey',
	},
	'&:before': {
		display: 'none',
	},
});

const NavPrevButton = styled('button')({
	height: '40px',
	width: '40px',
	borderStyle: 'solid',
	borderWidth: '1px',
	borderRadius: '4px',
	marginLeft: 30,
	marginRight: 30,
	left: -100,
	color: 'lightgrey',
	'&:hover': {
		display: 'block',
		color: 'lightgrey',
	},
	'&:before': {
		display: 'none',
	},
});

const SliderTitle = styled(Typography)({
	fontWeight: 'bold',
	padding: 10,
	marginTop: 50,
	marginBottom: 10,
});

const Month = styled(Typography)({
	fontSize: 13
});


interface DateCarouselProps {
  handleSelectDate: (dateValue: [], index?: number) => void;
  selectedDate: any[];
  activeDateIndex?: number | null;
  availabilitiesData: any;
  eventType?: string
}

const NextArrow = (props: any) => {
	const { className, style, onClick } = props;

	return (
		<NavNextButton className={className} onClick={onClick} style={{ ...style, display: 'block' }}>
			<ArrowForwardIosRoundedIcon />
		</NavNextButton>
	)
}

const PrevArrow = (props: any) => {
	const { className, style, onClick } = props;

	return (
		<NavPrevButton className={className} onClick={onClick} style={{ ...style, display: 'block' }}>
			<ArrowBackIosNewRoundedIcon />
		</NavPrevButton>
	)
}

const DateCarousel = ({
	handleSelectDate,
	activeDateIndex,
	availabilitiesData,
	eventType
}: DateCarouselProps) => {
	const { t, i18n } = useTranslation();

	const settings = {
		dots: false,
		infinite: false,
		speed: 300,
		slidesToShow: 6,
		slidesToScroll: 3,
		nextArrow: <NextArrow />,
		prevArrow: <PrevArrow />,
	};

	const getMonth = (date: string) => {
		return moment(date).locale(i18n.language).format('MMMM');
	};

	const getDay = (date: string) => {
		return moment(date).locale(i18n.language).format('dddd');
	};

	const getDayNumber = (date: string) => {
		return moment(date).locale(i18n.language).format('D')
	};

	let showMessage = true;
	let meetMessage = '';

	switch (eventType) {
		case eventTypeEnum.Service:
			meetMessage = t('meetLeo');
			break;
		case eventTypeEnum.Introduction:
			meetMessage = t('meetIntroduction');
			break;
		case eventTypeEnum.Portal:
			meetMessage = t('meetPortal');
			break;
		case eventTypeEnum.Epsp:
			meetMessage = t('meetSoutien');
			break;
		default:
			meetMessage = "";
			showMessage = false;
	}

	return (
		<Box sx={{ marginLeft: 10, marginRight: 10 }}>
			{ showMessage &&  <SliderTitle>{meetMessage}</SliderTitle> }
			<SliderTitle>{t('pickDay')}</SliderTitle>
			<DateContainer>
				<Slider {...settings}>
					{sortBy(Object.keys(availabilitiesData)).map((event, index) => {
						return (
							<DateButton
								key={index}
								onClick={() => handleSelectDate(availabilitiesData[event], index)}
							>
								<ButtonHeader>{getDay(event)}</ButtonHeader>
								<ButtonContent
									style={{
										backgroundColor: activeDateIndex === index ? '#C9E3FB' : '',
									}}
								>
									<Typography>{getDayNumber(event)}</Typography>
									<Month>{getMonth(event)}</Month>
								</ButtonContent>
							</DateButton>
						);
					})}
				</Slider>
			</DateContainer>
		</Box>
	);
};

export default DateCarousel;
