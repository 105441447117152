import React from 'react'
import DateCarousel from './DateCarousel';
import { styled } from '@mui/material/styles';
import {
	Typography,
	CircularProgress,
	Card,
	FormControl,
	Box,
	InputLabel,
	MenuItem,
	Select,
	Button,
} from '@mui/material';
import WarningIcon from '@mui/icons-material/Warning';
import TimeBlocsGrid from './TimeBlocsGrid';
import Form from './Form';
import { useTranslation } from 'react-i18next';
import { PresenceValue, relationEventTypeEnum, TimeData, UserData, eventTypeEnum, virtualTypeEnum } from '../types';
import { Controller, useForm } from 'react-hook-form';

const CenteredContainer = styled('div')({
	margin: 'auto',
	marginTop: '100px',
});

const LoaderTitle = styled(Typography)({
	marginTop: 10,
	fontSize: 14
});

const MyCard = styled(Card)({
	padding: 20,
	backgroundColor: '#C9E3FB'
});

const MyForm = styled(Box)({
	width: '300px',
	display: 'flex',
	flexDirection: 'column',
	alignItems: 'center',
	justifyContent: 'center',
	margin: '20px auto',
});

const FormTitle = styled(Typography)({
	marginTop: '100px',
	fontSize: 18,
});

const MyInputLabel = styled(InputLabel)({
	height: 40,
	backgroundColor: '#FFFFF',
});

const MySelect = styled(Select)({
	width: 300,
	marginBottom: 25,
});

const Submit = styled(Button)({
	marginTop: '20px',
});


interface EventPageProps {
    eventType?: string,
	selectedTime: string;
	availabilitiesData: any,
	activeTimeIndex: any;
	appLanguage: string; 
	onSubmitEventForm: (userData: UserData) => void;
	isLoading: boolean;
	errorMessage: boolean;
	handleSelectTime: (timeData: TimeData, index: any) => void;
	handleSelectDate: (dateValue: [], index: any) => void; 
	selectedDate: any[], 
	activeDateIndex: any;
	handleSubmitPresenceType: (event: any) => void;
	handleSubmitVirtualType: (event: any) => void;
	meetingPresenceType: string;
	meetingVirtualType: string;
	onSubmit: () => void,
	submitEventLoading: boolean;
}

const EventPage = ({
	eventType,
	selectedTime,
	availabilitiesData,
	activeTimeIndex,
	appLanguage,
	onSubmitEventForm,
	isLoading,
	errorMessage,
	handleSelectTime,
	handleSelectDate,
	selectedDate,
	activeDateIndex,
	handleSubmitPresenceType,
	handleSubmitVirtualType,
	meetingPresenceType,
	meetingVirtualType,
	onSubmit,
	submitEventLoading
}: EventPageProps) => {
	const { t } = useTranslation();

	const {
		handleSubmit,
		control,
		formState: { isSubmitSuccessful },
	} = useForm<PresenceValue>();

	const relationEventTypeList = [relationEventTypeEnum.Presential, relationEventTypeEnum.Virtual];

	const virtualTypeList = [virtualTypeEnum.Phone, virtualTypeEnum.Zoom]

	let infoMessage: string;
	let needsAssistanceLink = false;

	switch (eventType) {
	  case eventTypeEnum.Service:
	  case eventTypeEnum.Introduction:
	  case eventTypeEnum.Portal:
		infoMessage = t('info_leo');
		break;
	  case eventTypeEnum.Epsp:
		infoMessage =t('info_leo');
		break;
	  default:
		infoMessage = t('info');
		needsAssistanceLink = true;
	}

	const renderInfoMessage = () => {
	  if (isLoading) return null;

	  return (
		<p>
		  {infoMessage}{` `}
		  {needsAssistanceLink && <a href={t('url_needAssistance')} target='_BLANK' rel="noreferrer">{t('here')}</a>}
		</p>
	  );
	};

	const renderEventPage = () => {
		if (!isSubmitSuccessful && (eventType === eventTypeEnum.Relation || eventType === eventTypeEnum.Portal || eventType === eventTypeEnum.Epsp)) {
			return (
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormTitle>{t('selectOption')}</FormTitle>
					<MyForm>
						<Controller
							control={control}
							name="presenceType"
							render={({ field }) => (
								<FormControl>
									<MyInputLabel id="presenceType">{t('meetingType')}</MyInputLabel>
									<MySelect
										{...field}
										label={t('meetingType')}
										labelId="presenceType"
										onChange={(event: any) => handleSubmitPresenceType(event.target.value)}
										defaultValue={''}
									>
										{relationEventTypeList.map((value) => (
											<MenuItem key={value} value={value}>
												{t(value)}
											</MenuItem>
										))}
									</MySelect>
								</FormControl>
							)}
						/>
						<Submit
							type="submit"
							color="primary"
							variant="contained"
							disabled={meetingPresenceType === ''}
						>
							{t('next')}
						</Submit>
					</MyForm>
				</form>
			)
		} else if (!isSubmitSuccessful && (eventType === eventTypeEnum.Service || eventType === eventTypeEnum.Introduction)) {
			return(
				<form onSubmit={handleSubmit(onSubmit)}>
					<FormTitle>{t('selectOption')}</FormTitle>
					<MyForm>
						<Controller
							control={control}
							name="virtualType"
							render={({ field }) => (
								<FormControl>
									<MyInputLabel id="virtualType">{t('virtualType')}</MyInputLabel>
									<MySelect
										{...field}
										label={t('virtualType')}
										labelId="virtualType"
										onChange={(event: any) => handleSubmitVirtualType(event.target.value)}
										defaultValue={''}
									>
										{virtualTypeList.map((value) => (
											<MenuItem key={value} value={value}>
												{t(value)}
											</MenuItem>
										))}
									</MySelect>
								</FormControl>
							)}
						/>
						<Submit
							type="submit"
							color="primary"
							variant="contained"
							disabled={meetingVirtualType === ''}
						>
							{t('next')}
						</Submit>
					</MyForm>
				</form>
			)
		} else {
			return (
				<>
					{isLoading ? (
						<CenteredContainer>
							<CircularProgress />
							<LoaderTitle>{t('isLoading')}</LoaderTitle>
						</CenteredContainer>
					) : (
						<>
							{errorMessage ? (
								<CenteredContainer>
									<MyCard>
										<WarningIcon />
										<Typography>{t('noAvailabilities')}</Typography>
									</MyCard>
								</CenteredContainer>
							) : (
								<DateCarousel
									handleSelectDate={handleSelectDate}
									selectedDate={selectedDate}
									activeDateIndex={activeDateIndex}
									availabilitiesData={availabilitiesData}
									eventType={eventType}
								/>
							)}
						</>
					)}

					{selectedDate.length > 0 && (
						<TimeBlocsGrid
							handleSelectTime={handleSelectTime}
							selectedDate={selectedDate}
							activeTimeIndex={activeTimeIndex}
							appLanguage={appLanguage}
							eventType={eventType}
						/>
					)}

					{selectedDate.length > 0 && selectedTime !== '' && (
						<Form
							eventType={eventType}
							selectedTime={selectedTime}
							selectedDate={selectedDate}
							onSubmitEventForm={onSubmitEventForm}
							submitEventLoading={submitEventLoading}
						/>
					)}

					{ isLoading ? undefined : renderInfoMessage() }
				</>
			)
		}
	}


	return renderEventPage();
}

export default EventPage;