export enum eventTypeEnum {
  Support = "Questions",
  Service = "Première rencontre Léo",
  Relation = "Relation aide",
  Introduction = "Rencontre introductive - Relief",
  Portal = "Rencontre mon portrait actuel d'autogestion",
  Epsp = "Entente soutien ponctuel", 
}

export enum languageEnum {
  English = "Anglais",
  French = "Français",
}

export enum relationEventTypeEnum {
  Presential = "Présentiel",
  Virtual = "Virtuel",
}

export enum virtualTypeEnum {
  Phone = "Téléphone",
  Zoom = "Zoom",
}

export interface FormValues {
  eventType: string;
  language: string;
}

export interface PresenceValue {
  presenceType?: string;
  virtualType?: string;
}

export interface TimeData {
  durationInMinutes: string;
  startDateTime: string;
  endDateTime: string;
  ownerId: string;
  appointmentEndDateTime: string;
  appointmentInMinutes: string;
}

export interface UserData {
  firstname: string;
  lastname: string;
  phone: string;
  email: string;
}

export interface EventData {
  eventType: string;
  language: string;
}

export interface FormInputs {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  checkbox: boolean;
  checkbox_supp: boolean;
}
